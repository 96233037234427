import { CommonConstants, AuthenticationConstants, EventConfigConstants, ContentDataConstants } from "../../constants";
import { AuthApiCaller } from "../../middlewares/AuthApiCaller";
import { USER_TOKEN } from "../../utils/Storage";
import store from "../../store/store";
import { NavigateFunction } from "react-router-dom";
import { setHeaderNavURL, showError } from "../commonActions";
import { Welcome } from "../../constants/PageSettings";
// Functions

/**
 * This function will load the list of Role.
 *
 * @param savedCallback
 * The function to be fired when the list has arrived.
 *
 * This function will be provided with the
 * actionparam of the data to be fetched.
 *
 * @param saveFailedCallback
 * The function to be fired when the fetching of
 * the data failed.
 *
 * This function will be provided with the
 * error-id for the failure.
 *
 */
let intervalID: any = 0;
let timer: any = 0;

export const resetPassword = (
  _userName: string,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  AuthApiCaller.resetPassword(_userName)
    .then((response) => {
      store.dispatch({
        type: AuthenticationConstants.RESET_PASSWORD,
        payload: response
      });
      savedCallback && savedCallback(response);
    })
    .catch(() => {
      saveFailedCallback && saveFailedCallback("Invalid/Empty username or password!\nPlease try again...");
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const changePassword = (
  password: string,
  tfaDate: any,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  AuthApiCaller.changePassword(password, tfaDate)
    .then((response) => {
      store.dispatch({
        type: AuthenticationConstants.CHANGE_PASSWORD,
        payload: response
      });
      savedCallback && savedCallback(response);
    })
    .catch(() => {
      saveFailedCallback && saveFailedCallback("Invalid/Empty username or password!\nPlease try again...");
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

const configureSessionTimeOut = (lifeSpan: number) => {
  // you can configure as you want but here it is 1 min before token will get expired
  const timeout: any = lifeSpan * 1000 - 60 * 1000;
  sessionStorage.setItem("timeout", timeout);
  localStorage.removeItem("sessionTimeOut");
  localStorage.setItem("logOffCheck", "true");
  // this would reset localStorage before token expiry timer
  intervalID = setInterval(() => refreshToken(), timeout);
};

export const authenticate = async (_userName: string, _password: string, _tfaDate: any,
  saveFailedCallback?: () => void // @TODO - Remove Callback
) => {
  const response = await AuthApiCaller.login(_userName, _password, _tfaDate);
  try {
    if (response?.statusCode !== 201) {
      showError(response.errorMessage);
    } else {
      USER_TOKEN.set(response);
      store.dispatch({
        type: AuthenticationConstants.SIGN_IN,
        payload: response
      });
      store.dispatch({
        type: EventConfigConstants.SET_PAGE_SETTING,
        payload: Welcome
      });
      setHeaderNavURL(true);
      configureSessionTimeOut(response?.lifeSpan);
    }
  } catch (e) {
    console.log("exception occured ", e);
    e && saveFailedCallback && saveFailedCallback();
  }

  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const onChangePassword = (result: boolean, twoFACheck: boolean) => {
  if (!result) {
    store.dispatch({
      type: AuthenticationConstants.PASSWORD_CHANGED,
      payload: result
    });
    if (!twoFACheck) {
      store.dispatch({
        type: EventConfigConstants.SET_PAGE_SETTING,
        payload: { screen: Welcome }
      });
    }
  }
};

export const logoff = async () => {
  console.log("logoff and clear the Cache/Session");

  try {
    const response = await AuthApiCaller.logout();
    // if (response?.statusCode === 200) {
    store.dispatch({
      type: ContentDataConstants.GET_API_LOADER,
      payload: ""
    });
    store.dispatch({
      type: AuthenticationConstants.SIGN_OUT,
      payload: response.result.redirectUrl
    });
    USER_TOKEN.remove();
    sessionStorage.removeItem("isSignedIn");
    clearInterval(intervalID);
    removeLocals();
  } catch (e) {
    store.dispatch({
      type: AuthenticationConstants.SIGN_OUT,
      payload: null
    });
    USER_TOKEN.remove();
    clearInterval(intervalID);
    removeLocals();
    // console.log("exception occured ", e);
  }
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const removeLocals = () => {
  localStorage.removeItem("isReview");
  localStorage.removeItem("hideButtons");
  localStorage.setItem("logOffCheck", "false");
  localStorage.removeItem("formStatus");
};

export const refreshToken = () => {
  console.log("USER_TOKEN: ", USER_TOKEN.get());

  const { token, refreshToken }: any = USER_TOKEN.get();
  AuthApiCaller.refreshTokenRoute(token, refreshToken)
    .then((response) => {
      if (response && response && response.statusCode < 204) {
        USER_TOKEN.set(response);
        store.dispatch({
          type: AuthenticationConstants.REFRESH_TOKEN,
          payload: response
        });
        /* savedCallback && savedCallback(response);
      } else {
        saveFailedCallback && saveFailedCallback(); */
      } else {
        clearInterval(intervalID);
        logoff();
      }
    })
    .catch((error: any) => {
      clearInterval(intervalID);
      logoff();
      console.log("error: ", error);
      // error && saveFailedCallback && saveFailedCallback();
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const uploadEmployeeDocuments = (
  fileData: FormData,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: any) => void // @TODO - Remove Callback
) => {
  AuthApiCaller.uploadEmployeeDocuments(fileData)
    .then((response) => {
      store.dispatch({
        type: AuthenticationConstants.UPLOAD_EmployeeFILE_INFO,
        payload: response
      });
      savedCallback && savedCallback(response);
    })
    .catch((error: any) => {
      saveFailedCallback && saveFailedCallback(error.response.validationErrors.length > 0 ? error.response.validationErrors : error.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getTFARegContent = (navigate?: NavigateFunction) => {
  AuthApiCaller.getTFARegContent()
    .then((response) => {
      if (response.statusCode === 200) {
        USER_TOKEN.set(response);
        store.dispatch({
          type: AuthenticationConstants.GET_TWOFA_REG_CONTENT,
          payload: response
        });
      } else {
        showError(response.errorMessage);
        clearInterval(intervalID);
        navigate?.("/logoff");
      }
    })
    .catch((error: any) => {
      console.log("exception occured ", error);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getTFARegSelection = async (TwoFATypeID: any, isRegistration: any, navigate?: NavigateFunction) => {
  await AuthApiCaller.TwoFARegistrationSelection(isRegistration, TwoFATypeID)
    .then((response) => {
      if (response.statusCode === 200) {
        USER_TOKEN.set(response);
        store.dispatch({
          type: AuthenticationConstants.GET_REGISTRATION_FORM,
          payload: response
        });
      } else {
        showError(response?.errorMessage);
        clearInterval(intervalID);
        navigate?.("/logoff");
      }
    })
    .catch((error: any) => {
      console.log("exception occured ", error);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const sendVerificationCode = async (
  options: any,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  await AuthApiCaller.sendVerificationCode(options)
    .then((response) => {
      if (response.statusCode === 200) {
        store.dispatch({
          type: AuthenticationConstants.SEND_VERIFICATION_CODE,
          payload: response
        });
        savedCallback && savedCallback(true);
      } else {
        saveFailedCallback && saveFailedCallback(response.errorMessage);
      }
    })
    .catch((error: any) => {
      console.log("exception occured ", error);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getVerificationContent = (param: any) => {
  AuthApiCaller.getTFAVerificationContent(param)
    .then((response) => {
      if (response.statusCode === 200) {
        store.dispatch({
          type: AuthenticationConstants.GET_VERIFICATION_CONTENT,
          payload: response
        });
      } else {
        showError(response?.errorMessage);
        clearInterval(intervalID);
        logoff();
      }
    })
    .catch((error: any) => {
      console.log("exception occured ", error);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const validateTFACode = async (
  options: any,
  navigate?: NavigateFunction,
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  try {
    const response = await AuthApiCaller.validateTFACode(options);

    if (response.statusCode === 401) {
      USER_TOKEN.remove();
      response?.errorMessage && showError(response.errorMessage)
      navigate?.("/logoff");
    } else if (response.statusCode !== 201) {
      saveFailedCallback && saveFailedCallback(response.errorMessage);
    } else if (response.statusCode === 422) {
      saveFailedCallback && saveFailedCallback(response.errorMessage);
    } else {
      USER_TOKEN.set(response);
      sessionStorage.removeItem("needTwoFARegistration");
      sessionStorage.removeItem("needTwoFAValidation");
      sessionStorage.removeItem("isRegistration");
      sessionStorage.removeItem("twoFATypeID");
      store.dispatch({
        type: AuthenticationConstants.SIGN_IN,
        payload: response
      });
      store.dispatch({
        type: EventConfigConstants.SET_PAGE_SETTING,
        payload: Welcome
      });
      setHeaderNavURL(true);

      configureSessionTimeOut(response?.lifeSpan);
      const cdt = new Date();
      const tfaDate = {
        day: cdt.getDate(),
        month: cdt.getMonth() + 1,
        year: cdt.getFullYear()
      };
      localStorage.setItem("tfaDate", JSON.stringify(tfaDate));
    }
  } catch (e) {
    console.log("exception occured ", e);
  }

  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getTwoFASettings = () => {
  AuthApiCaller.getTwoFASettings()
    .then((response) => {
      if (response.statusCode === 200) {
        store.dispatch({
          type: AuthenticationConstants.GET_TFA_SETTINGS,
          payload: response
        });
      } else {
        showError(response?.errorMessage);
      }
    })
    .catch((error: any) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const disableTwoFA = async (successCallback) => {
  try {
    const response = await AuthApiCaller.disableTwoFA();
    store.dispatch({
      type: AuthenticationConstants.TFA_DISABLE,
      payload: response
    });
    successCallback && successCallback(true);
  } catch (e) {
    console.log("exception occured ", e);
  }

  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const loginSSO = (payload: any) => {
  //Test implementation for SSO validation. TODO
  try {
    if (payload.token) {
      USER_TOKEN.set(payload);
      store.dispatch({
        type: AuthenticationConstants.SIGN_IN,
        payload: payload
      });
      // you can configure session Timeout
      configureSessionTimeOut(payload?.lifeSpan);
    }
  } catch (e) {
    console.log("Error during SSO ", e);
  }
};

export const getSSOtoken = (
  req: any,
  navigate: NavigateFunction,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  AuthApiCaller.getSSOtokenCaller(req)
    .then((response) => {
      if (response && response?.statusCode < 204) {
        delete response.version;
        delete response.statusCode;
        loginSSO(response);
        // getMainMenuContent(false, navigate);
        savedCallback && savedCallback(response);
      } else {
        saveFailedCallback && saveFailedCallback(response?.errorMessage);
        clearInterval(intervalID);
        navigate("/logoff");
      }
    })
    .catch((error: any) => {
      saveFailedCallback && saveFailedCallback(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const idleTimer = (navigate: NavigateFunction): void => {
  clearTimeout(timer);
  resetTimer(navigate);
};

const resetTimer = (navigate: NavigateFunction): void => {
  const timeout: string | null = sessionStorage.getItem("timeout");
  // const timeOutInterval = parseInt(timeout) - 60 * 1000;
  const timeOutInterval: number = timeout ? parseInt(timeout) - 60 * 1000 : 0;

  const logout = (): void => {
    localStorage.setItem("sessionTimeOut", "1");
    if (localStorage?.getItem("sessionTimeOut") === "1") {
      console.log("Session timeout!!");
      setTimeout(() => {
        clearTimeout(timer);
        clearInterval(intervalID);
        navigate("/logoff");
      }, 100);
    }
  };
  timer = setTimeout(logout, timeOutInterval); // time is in milliseconds
};
